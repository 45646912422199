import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import { Constrain, GeneralBody } from '../components/Containers';
import { Article, PageTitle } from '../components/Typography';
import { MDXRenderer } from 'gatsby-plugin-mdx';

type PageContextType = {
	slug: string;
};

export default function RootTemplate(
	props: PageProps<GatsbyTypes.RootPageDataQuery, PageContextType>
) {
	const { data } = props;

	return (
		<>
			<Seo
				title={data?.rootPage?.frontmatter?.title}
				description={data.rootPage?.frontmatter?.description}
			/>
			<Constrain className="narrow">
				<GeneralBody>
					<PageTitle>{data?.rootPage?.frontmatter?.title}</PageTitle>
					<Article>
						<MDXRenderer>{data?.rootPage!.body}</MDXRenderer>
					</Article>
				</GeneralBody>
			</Constrain>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query RootPageData($slug: String!) {
		rootPage: mdx(fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				description
			}
			body
		}
	}
`;
